#photo-picker-input {
  display: none;

  & + #sharing-button {
    margin-left: 10px;
  }
}

#photo-picker {
  background-color: #ffffff;
  color: #474747;
  flex: 1;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .icon-add-photo {
    margin-right: 5px;
  }
}
