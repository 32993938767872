#close-preview-button {
  cursor: pointer;
  margin: 20px;
  margin-bottom: 0;
  width: 16px;
  border: 2px solid #777;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  font-size: 14px;
  color: #777;
  font-weight: 900;
}
