#sharing-button {
  flex: 1;
  padding: 0;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  &.ready-to-share {
  }
}
