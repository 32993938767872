@import "colors";

#model-switcher {
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, $background, rgba(255, 255, 255, 0));
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, $background, rgba(255, 255, 255, 0));
  }

  p {
    position: relative;
    text-align: center;
    padding-bottom: 8px;
    color: $dark;
    margin-bottom: 8px;

    &::after {
      content: ' ';
      position: absolute;
      left: 15%;
      bottom: 0;
      right: 15%;
      background-color: $dark;
      height: 1px;
    }
  }

  #available-models {
    display: flex;
    padding: 8px 15px;
    overflow-x: auto;

    .model {
      cursor: pointer;
      margin-right: 10px;
      box-shadow: 0 1px 12px rgba(0, 0, 0, 0.14);
      background-color: #ffffff;
      border-radius: 20px;
      border: 4px solid #ffffff;

      &.selected {
        border-color: #555;
      }

      img {
        height: 100px;
        width: 85px;
        border-radius: 20px;
      }
    }
  }
}
