@import "colors";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  background-color: $background;
  -webkit-font-smoothing: antialiased;
}

#react-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

canvas {
  cursor: -webkit-grab;
}

h1 {
  color: #222;
  margin: 0;
  font-size: 22px;
}

p {
  color: #555;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
}

.button {
  height: 40px;
  border: 0;
  background-color: #ffffff;
  border-radius: 28px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  line-height: 40px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2px;
  text-align: center;
  transition: all 250ms;
  position: relative;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.12);
  margin: auto;

  &.primary {
    background-color: $primary;
    color: #ffffff;
  }

  &.dark {
    background-color: $dark;
    color: #ffffff;
  }

  &.large {
    width: 80%;
  }

  & + .button {
    margin-top: 10px;
  }
}

.responsive-wrapper {
  max-width: 412px;
  margin: auto;
}
