#sharing-to-facebook-button {
  background-color: #3b5998;
  color: #fff;
  margin-bottom: 10px;

  .fb-icon {
    font-size: 18.3px;
    line-height: 18.3px;
    vertical-align: -15%;
  }
}
