#scene-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: auto;
  position: relative;
  border-radius: 10px;

  &.is-previewing {
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.14);
    margin: 20px auto;
  }
}
