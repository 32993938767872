header {
  padding: 20px 0;
  padding-bottom: 0;

  h1 {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 900;
    font-style: normal;
    text-align: center;
  }

  h2 {
    margin: 0;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    color: #333;
  }
}
