a.feedback-link {
  color: #555;
  text-decoration: none;
  margin-top: 20px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    text-decoration-skip: ink;
  }
}
