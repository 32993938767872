@import "../colors";

.photo-editor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: $background;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  .apply-updates {
    padding: 0 20px;
    margin: initial;
  }

  .cropper {
    position: relative;
    width: 100%;
    height: 50%;
  }

  .scaling-range {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    padding: 10px 15px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .hint {
    padding: 8px;
    text-align: center;
    color: #9d9d9d;
    font-weight: 500;
  }
}
